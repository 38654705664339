<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
            !hideOrganization
          "
        >
          <organization-selector
            :organization="weeklyReport.organization.id"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId) => {
                weeklyReport.organization.id = organizationId;
                onFormChanged();
              }
            "
            :disabled="!!weeklyReport.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>
    </div>
    <base-input
      :label="`${$t('WEEKLY_REPORTS.STARTS_AT')}`"
      :placeholder="$t('WEEKLY_REPORTS.STARTS_AT')"
    >
      <flat-picker
        :config="{
          allowInput: true,
          enableTime: false,
          dateFormat: 'Y-m-d',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="weeklyReport.starts_at"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.starts_at" />
    <base-input
      :label="`${$t('WEEKLY_REPORTS.ENDS_AT')}`"
      :placeholder="$t('WEEKLY_REPORTS.ENDS_AT')"
    >
      <flat-picker
        :config="{
          allowInput: true,
          enableTime: false,
          dateFormat: 'Y-m-d',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="weeklyReport.ends_at"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.ends_at" />

    <div>
      <base-input
        :label="`${$t('COMMON.SERVICECENTER')}`"
        :placeholder="$t('COMMON.SERVICECENTER')"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
          !hideOrganization
        "
      >
        <service-center-selector
          :filterOrganization="weeklyReport.organization?.id"
          :serviceCenter="weeklyReport.serviceCenter?.id"
          :filterable="true"
          :showAll="false"
          @serviceCenterChanged="
            (serviceCenterId) => {
              weeklyReport.serviceCenter.id = serviceCenterId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.serviceCenter" />
    </div>

    <div>
      <base-input
        :label="`${$t('COMMON.ESTABLISHMENT')}`"
        :placeholder="$t('COMMON.ESTABLISHMENT')"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
          !hideOrganization
        "
      >
        <establishment-selector
          :filterOrganization="weeklyReport.organization?.id"
          :establishment="weeklyReport.establishment?.id"
          :filterable="true"
          :filterServiceCenter="weeklyReport.serviceCenter?.id"
          :showAll="false"
          @establishmentChanged="
            (establishmentId) => {
              weeklyReport.establishment.id = establishmentId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.establishment" />
    </div>

    <div>
      <base-input
        :label="`${$t('COMMON.CAFETERIA')}`"
        :placeholder="$t('COMMON.CAFETERIA')"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS) &&
          !hideOrganization
        "
      >
        <cafeteria-selector
          :filterOrganization="weeklyReport.organization?.id"
          :cafeteria="weeklyReport.cafeteria?.id"
          :filterEstablishment="weeklyReport.establishment?.id"
          :filterable="true"
          :showAll="false"
          @cafeteriaChanged="
            (cafeteriaId) => {
              weeklyReport.cafeteria.id = cafeteriaId;

              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.cafeteria" />
    </div>

    <div>
      <div class="mb-1 font-weight-bold" :key="renderKey">
        <template v-if="!weeklyReport.report_uploaded_file">
          {{ $t("COMMON.REPORT_FILE") }}
        </template>
        <template v-if="weeklyReport.report_uploaded_file">
          <a
            :href="weeklyReport.report_uploaded_file"
            target="_blank"
            class="text-primary"
          >
            {{ $t("COMMON.REPORT_FILE") }} -
            {{ fileName ?? weeklyReport.report_uploaded_file }}
          </a>
        </template>
      </div>
      <base-input>
        <dropzone-file-upload
          :url="uploadUrl"
          @addedfile="fileAdded"
          @queuecomplete="filesUpdated"
        >
        </dropzone-file-upload>
      </base-input>
    </div>

    <!-- <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('WEEKLY_REPORTS.COUNTER_SALES')} `"
          v-model="weeklyReport.counter_sales"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.counter_sales" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('WEEKLY_REPORTS.BILLING')}`"
          v-model="weeklyReport.billing"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.billing" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('WEEKLY_REPORTS.DISTRIBUTOR_SALES')}`"
          v-model="weeklyReport.distributor_sales"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.distributor_sales" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('WEEKLY_REPORTS.COUNTER_PURCHASE')}`"
          v-model="weeklyReport.counter_purchase"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.counter_purchase" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('WEEKLY_REPORTS.STATIONERY')}`"
          v-model="weeklyReport.stationery"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.stationery" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('WEEKLY_REPORTS.CLEANING_AND_DISPOSABLE_PRODUCTS')}`"
          v-model="weeklyReport.cleaning_and_disposable_products"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error
          :errors="apiValidationErrors.cleaning_and_disposable_products"
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('WEEKLY_REPORTS.OTHERS')}`"
          v-model="weeklyReport.others"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.others" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('WEEKLY_REPORTS.MAINTENANCE')}`"
          v-model="weeklyReport.maintenance"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.maintenance" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('WEEKLY_REPORTS.DISTRIBUTOR')}`"
          v-model="weeklyReport.distributor"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.distributor" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('WEEKLY_REPORTS.WAGES')}`"
          v-model="weeklyReport.wages"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.wages" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('WEEKLY_REPORTS.RENT')}`"
          v-model="weeklyReport.rent"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.rent" />
      </div>
    </div> -->

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          weeklyReport.id
            ? $t("WEEKLY_REPORTS.EDIT_WEEKLY_REPORT")
            : $t("WEEKLY_REPORTS.ADD_WEEKLY_REPORT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
// import HtmlEditor from "@/components/Inputs/HtmlEditor";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";
import CafeteriaSelector from "@/components/CafeteriaSelector.vue";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import weeklyReports from "../../../../../i18n/fr/weeklyReports";
import swal from "sweetalert2";

const apiUrl = process.env.VUE_APP_API_BASE_URL;

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    EstablishmentSelector,
    CafeteriaSelector,
    ServiceCenterSelector,
    flatPicker,
    DropzoneFileUpload,
    // HtmlEditor,

    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: [
    "weeklyReportData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideSubmit",
  ],

  computed: {
    uploadUrl() {
      return `${apiUrl}/uploads/weekly-reports/${
        this.weeklyReportData.id ?? 0
      }/uploaded-report`;
    },
  },

  data() {
    let weeklyReportData = { ...this.weeklyReportData };
    weeklyReportData = this.$fillUserOrganizationData(weeklyReportData);
    return {
      weeklyReport: weeklyReportData,
      fileName: null,
      renderKey: 0,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let weeklyReportData = cloneDeep(this.weeklyReport);
      weeklyReportData = this.$fillUserOrganizationData(weeklyReportData);
      this.$emit("weeklyReportSubmitted", weeklyReportData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    fileAdded() {
      this.fileName = null;
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
    },

    filesUpdated(data, files) {
      files = files.filter((item) => item.status === "success");
      if (files.length === 0) {
        swal.close();
        return;
      }
      const file = files[0];

      this.fileName = file.name;
      this.weeklyReport.report_uploaded_file = JSON.parse(
        file.xhr.response
      ).url;
      this.renderKey = this.renderKey + 1;
      swal.close();
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    weeklyReportData(weeklyReportData) {
      if (weeklyReportData) {
        this.weeklyReport = {
          ...this.weeklyReport,
          ...cloneDeep(weeklyReportData),
        };
      }
    },
  },
};
</script>
