var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
          !_vm.hideOrganization
        )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.weeklyReport.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.weeklyReport.id},on:{"organizationChanged":(organizationId) => {
              _vm.weeklyReport.organization.id = organizationId;
              _vm.onFormChanged();
            }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1)]),_c('base-input',{attrs:{"label":`${_vm.$t('WEEKLY_REPORTS.STARTS_AT')}`,"placeholder":_vm.$t('WEEKLY_REPORTS.STARTS_AT')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        enableTime: false,
        dateFormat: 'Y-m-d',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.weeklyReport.starts_at),callback:function ($$v) {_vm.$set(_vm.weeklyReport, "starts_at", $$v)},expression:"weeklyReport.starts_at"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.starts_at}}),_c('base-input',{attrs:{"label":`${_vm.$t('WEEKLY_REPORTS.ENDS_AT')}`,"placeholder":_vm.$t('WEEKLY_REPORTS.ENDS_AT')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        enableTime: false,
        dateFormat: 'Y-m-d',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.weeklyReport.ends_at),callback:function ($$v) {_vm.$set(_vm.weeklyReport, "ends_at", $$v)},expression:"weeklyReport.ends_at"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.ends_at}}),_c('div',[(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
        !_vm.hideOrganization
      )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SERVICECENTER')}`,"placeholder":_vm.$t('COMMON.SERVICECENTER')}},[_c('service-center-selector',{attrs:{"filterOrganization":_vm.weeklyReport.organization?.id,"serviceCenter":_vm.weeklyReport.serviceCenter?.id,"filterable":true,"showAll":false},on:{"serviceCenterChanged":(serviceCenterId) => {
            _vm.weeklyReport.serviceCenter.id = serviceCenterId;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.serviceCenter}})],1),_c('div',[(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
        !_vm.hideOrganization
      )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ESTABLISHMENT')}`,"placeholder":_vm.$t('COMMON.ESTABLISHMENT')}},[_c('establishment-selector',{attrs:{"filterOrganization":_vm.weeklyReport.organization?.id,"establishment":_vm.weeklyReport.establishment?.id,"filterable":true,"filterServiceCenter":_vm.weeklyReport.serviceCenter?.id,"showAll":false},on:{"establishmentChanged":(establishmentId) => {
            _vm.weeklyReport.establishment.id = establishmentId;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.establishment}})],1),_c('div',[(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CAFETERIAS) &&
        !_vm.hideOrganization
      )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CAFETERIA')}`,"placeholder":_vm.$t('COMMON.CAFETERIA')}},[_c('cafeteria-selector',{attrs:{"filterOrganization":_vm.weeklyReport.organization?.id,"cafeteria":_vm.weeklyReport.cafeteria?.id,"filterEstablishment":_vm.weeklyReport.establishment?.id,"filterable":true,"showAll":false},on:{"cafeteriaChanged":(cafeteriaId) => {
            _vm.weeklyReport.cafeteria.id = cafeteriaId;

            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cafeteria}})],1),_c('div',[_c('div',{key:_vm.renderKey,staticClass:"mb-1 font-weight-bold"},[(!_vm.weeklyReport.report_uploaded_file)?[_vm._v(" "+_vm._s(_vm.$t("COMMON.REPORT_FILE"))+" ")]:_vm._e(),(_vm.weeklyReport.report_uploaded_file)?[_c('a',{staticClass:"text-primary",attrs:{"href":_vm.weeklyReport.report_uploaded_file,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.REPORT_FILE"))+" - "+_vm._s(_vm.fileName ?? _vm.weeklyReport.report_uploaded_file)+" ")])]:_vm._e()],2),_c('base-input',[_c('dropzone-file-upload',{attrs:{"url":_vm.uploadUrl},on:{"addedfile":_vm.fileAdded,"queuecomplete":_vm.filesUpdated}})],1)],1),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.weeklyReport.id ? _vm.$t("WEEKLY_REPORTS.EDIT_WEEKLY_REPORT") : _vm.$t("WEEKLY_REPORTS.ADD_WEEKLY_REPORT"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }